<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>高校就业</el-breadcrumb-item>
                <el-breadcrumb-item>报到证查询</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="yybox">
            <div class="bdzsearch flex align-center justify-center">
                <el-input class="bdzinput" size="large" v-model="form.name" placeholder="输入手机号搜索" style="width:500px; margin-right:10px;"></el-input>
                <el-button type="primary" size="large">搜索</el-button>
            </div>
            <div class="result">
                <el-result icon="success" title="预约成功" subTitle="您的报到证已预约成功！">
                    <template slot="extra">
                        <el-button type="primary" size="medium">返回</el-button>
                    </template>
                </el-result>
            </div>
        </div>
    </div>
</template>
<script>
import Uploadimg from '@/components/upload/uploadImg.vue'
export default{
    components:{
        Uploadimg,
    },
    data(){
        return{
            form:{}
        }
    }
}
</script>

<style scoped>
.wz{ width: 1200px; margin: 0 auto; padding: 16px 0;}
.yybox{ background: #fff; width: 1200px; margin: 20px auto 40px auto; padding: 30px;}


</style>


